import * as React from 'react'
import { withTranslation } from "react-i18next"

require ('air-datepicker/dist/css/datepicker.min.css')
require ('./booking-widget.scss')

class BookingLine extends React.Component {

    state = {
        arrivalDate: '',
        arrivalDateObj: '',
        formattedArrivalDate: '',
        departureDate: '',
        departureDateObj: '',
        selectedChildCount: '',
        selectedAdultCount: '',
        nights: ''
    }

    handleChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
    
        this.setState({
          [name]: value,
        })
    }

    countNights() {
        return this.dateDiffInDays(this.state.departureDateObj, this.state.arrivalDateObj)
    }

    getFormattedDate(date) {
        return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
    }

    isBookingValid = () => this.state.arrivalDate !== '' &&
            this.state.departureDate !== '' &&
            this.state.selectedAdultCount !== ''

    handleSubmit = event => {
        event.preventDefault()
        
        if (this.isBookingValid()) {
            this.setState(
                {nights: this.countNights()},
                () => {
                    this.setState(
                        {
                            formattedArrivalDate: this.getFormattedDate(this.state.arrivalDateObj)
                        },
                        () => {
                            this.refs.bookingWidgetForm.submit()
                        }
                    )
                }
            )
        }
    }

    dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        
        return Math.floor(Math.abs(utc2 - utc1) / _MS_PER_DAY);
    }
    
    componentDidMount() {

        const { locale } = this.props.localeData
        const $ = require('jquery')
        require("air-datepicker")
        require("air-datepicker/dist/js/i18n/datepicker." + locale)

        const dpInit = {
            language: locale,
            minDate: new Date(),
            position: "top left"
        }

        if (dpInit.language === 'hu') {
            dpInit.dateFormat = "yyyy.mm.dd.";
        }

        const arrivalInit = Object.assign({
            onSelect: (formattedDate, newDate, instance) => {
                this.setState({arrivalDate: formattedDate})
                const departureDatepicker = $(".widget-departure-date").datepicker().data("datepicker");
                this.setState({arrivalDateObj: newDate})
                if (newDate > this.state.departureDate) {
                    this.setState({departureDate: ''})
                } 
                let nextDay = new Date(newDate);
                nextDay.setDate(newDate.getDate() + 1);
                departureDatepicker.update("minDate", nextDay);
                instance.hide();
            }
        }, dpInit)

        const departureInit = Object.assign({
            onSelect: (formattedDate, newDate, instance) => {
                this.setState({departureDate: formattedDate})
                const arrivalDatepicker = $(".widget-arrival-date").datepicker().data("datepicker");
                this.setState({departureDateObj: newDate})
                if (newDate < this.state.arrivalDate) {
                    this.setState({arrivalDate: ''})
                } 
                let lastDay = new Date(newDate);
                lastDay.setDate(newDate.getDate() - 1);
                arrivalDatepicker.update("maxDate", lastDay);
                instance.hide();
            }
        }, dpInit)

        $(".widget-arrival-date").datepicker(arrivalInit);
        $(".widget-departure-date").datepicker(departureInit);
    }

    render() {
        const { locale } = this.props.localeData
        const { roomId } = this.props
        const { t } = this.props

        return (<>
                    <h3 class="room-details-title">{t('booking')}</h3>
                    <form ref="bookingWidgetForm" onSubmit={this.handleSubmit} className="booking-line-form search-form" action="https://www.secure-hotel-booking.com/The-Hotel-Unforgettable-Hotel-Tiliana/2QIX/search?hotelId=21043">
                        <div className="form-container fix">
                        <div className="box-select">
                            <div className="select date">
                                <input 
                                id="user-select-date" 
                                value={this.state.arrivalDate}
                                className="bookline-datepicker widget-arrival-date"
                                placeholder={t('arrival')} 
                                type="text"
                                autoComplete="off" />
                            </div>
                            <div className="select date">
                                <input 
                                id="user-select-to-date" 
                                name="departureDate"
                                value={this.state.departureDate}
                                className="bookline-datepicker widget-departure-date"
                                placeholder={t('departure')} 
                                type="text" 
                                autoComplete="off" />
                            </div>
                            <div className="select arrow">
                                <select onChange={this.handleChange} value={this.state.selectedAdultCount} name="selectedAdultCount">
                                    <option>{t('adults')}</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                </select>
                            </div>
                            <div className="select arrow">
                                <select onChange={this.handleChange} value={this.state.selectedChildCount} name="selectedChildCount">
                                    <option>{t('children')}</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                </select>
                            </div>
                        </div>
                        <input type="hidden" name="arrivalDate" value={this.state.formattedArrivalDate} />
                        <input type="hidden" name="nights" value={this.state.nights} />
                        <input type="hidden" name="language" value={locale} />
                        <input type="hidden" name="roomId" value={roomId} />
                        <input type="hidden" id="crossSell" name="crossSell" value="false" />
                        <button type="submit" className="check-booking-btn default-btn">{t('check')}</button>
                    </div>
                </form>
            </> 
        )
    }
}

export default withTranslation()(BookingLine)