import React from "react";
import { withTranslation } from "react-i18next";
import Modal from '../Modal'
import NetlifyForm from '../NetlifyForm'
import Children from 'react-children-utilities';

require ('./smart-form.scss')

class SmartForm extends React.Component {

    HIDE_TIMEOUT = 3000
    
    stateProps = {}

    state = {
        showSuccess: false,
        showWarning: false
    }

    constructor(props) {
        super(props)
        this.stateProps = props.stateProps
        this.stateValues = props.stateValues || null
        this.defaultMessageFields = props.defaultMessageFields || null
        props.stateProps.map((prop) => this.setState({[prop]: ""}))
    }

    getState = () => {
        let states = {}
        this.stateProps.forEach(prop => {
            if (this.stateValues !== null && typeof this.stateValues[prop] === 'function') {
                states[prop] = this.stateValues[prop]()
            } else {
                states[prop] = this.state[prop]
            }
        });

        if (states.message === undefined && this.defaultMessageFields !== null) {
            states.message = this.defaultMessageFields.map(field => states[field]).join(" - ")
        }

        return states
    }

    validateForm = () => {
        return true
    }

    handleChange = event => {
        const target = event.target
    
        this.setState({
          [target.name]: target.value,
          showSuccess: false,
          showWarning: false
        })
    }

    onFormSuccess = message => {
        this.setState({showSuccess: true, name: "", email: "", message: ""})
        setTimeout(() => this.setState({showSuccess: false}), this.HIDE_TIMEOUT)
    }

    onFormError = message => {
        this.setState({showError: true})
    }

    closeSuccess = () => {
        this.setState({showSuccess: false})
    }

    closeWarning = () => {
        this.setState({showWarning: false})
    }

    render() {
        
        const { successTitle, successMessage, formClassName, formName, mailSubject, stateProps, t } = this.props
        const children = Children.deepMap(this.props.children, function(child) {
            if (child !== null && child.props.name !== undefined) {
                let extraChildProps = { value: this.state[child.props.name], onChange: this.handleChange }             
                return React.cloneElement(child, extraChildProps);
            } else {
                return child;
            }
        }.bind(this));

        return (
        <>
            <Modal show={this.state.showSuccess}
                    type="success" 
                    onClose={this.closeSuccess} 
                    title={successTitle} 
                    message={successMessage} 
                    />
            <div className={"alert alert-warning alert-dismissible fade" + (this.state.showWarning ? " show" : " d-none")} role="alert">
                <p><strong>{t('warning')}</strong>{t(this.state.warningMessage)}</p>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.closeWarning}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <NetlifyForm 
                subject={mailSubject} 
                className={"smart-form " + formClassName} 
                name={formName} 
                onSuccess={this.onFormSuccess} 
                onError={this.onFormError} 
                state={this.getState()} 
                action="/">
                <p className="d-none">
                    <label>Don’t fill this out if you're human: <input type="text" name="age" value="18" /></label>
                </p>
                { children }
            </NetlifyForm>
        </>
        )
    }
}

export default withTranslation()(SmartForm)