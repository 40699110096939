import React from "react"
import { withTranslation } from "react-i18next"
import InstagramWidget from "../InstagramWidget"
import { slickCarouselInit } from "./slick-carousel-init"
import 'slick-carousel/slick/slick.scss'
import SocialShare from "../SocialShare"
import SmartForm from "../SmartForm"
import BookingWidget from "../BookingWidget"
import './room.scss'

class Room extends React.Component {

    componentDidMount() {
        slickCarouselInit()
    }

    render() {
        const { content, instaFeed, data, localeData, t } = this.props

        return(
            <div className="room-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="room-image-gallery-wrap">
                                <div className="room-image-large">
                                    {data.roomSlides.map(({image: {childImageSharp: item}}) => (
                                    <div className="room-image img-full">
                                        <img srcSet={item.fluid.srcset} src={item.fluid.src} sizes={item.fluid.sizes} />
                                        <div className="cost">
                                            <span className="room-from">{t('room_from')}</span>
                                            <h2>{data.price}</h2>
                                            <span className="per-night">{t('per_person_per_night')}</span>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                <div className="room-image-thumbs">
                                {data.roomSlides.map(({image: {childImageSharp: item}}) => (										
                                    <div className="sm-image magnify-icon"><img srcSet={item.fluid.srcset} src={item.fluid.src} sizes={item.fluid.sizes} /></div>
                                ))}
                                </div>
                            </div>
                            <div className="room-description">
                                <h3>{t('room_details')}</h3>
                                <p dangerouslySetInnerHTML={{__html: content}} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-widget">
                                <BookingWidget localeData={localeData} roomId={data.roomId} />
                            </div>
                            <div className="sidebar-widget">
                                <h3 className="room-details-title">{t('room_details')}</h3>
                                <ul className="vertical_list">
                                    <li>
                                        <p>
                                            <i className="fa fa-bed"></i>
                                             {t('bed')}:&nbsp;
                                             <span>
                                                 {data.bed}
                                            </span>
                                        </p>
                                    </li>
                                    {data.extraBed && (
                                    <li>
                                        <p>
                                            <i className="fa fa-bed"></i>
                                             {t('extra_bed')}:&nbsp;
                                             <span>
                                                 {data.extraBed}
                                            </span>
                                        </p>
                                    </li>
                                    )}
                                    <li>
                                        <p>
                                            <i className="fa fa-binoculars"></i>
                                             {t('view')}:&nbsp;
                                             <span>
                                                 {data.view}
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <i className="fa fa-wifi"></i>
                                             {t('wifi')}:&nbsp;
                                             <span>
                                                 {data.wifi}
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <i className="fa fa-coffee"></i>
                                             {t('breakfast')}:&nbsp;
                                             <span>
                                                 {data.breakfast}
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <i className="fa fa-users"></i>
                                             {t('max_guest')}:&nbsp;
                                             <span>
                                                 {data.max_guest}
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <img src="/images/spa-icon.svg" className="spa-icon" alt="" />
                                        <strong>{t('wellness_and_spa')}</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="question-area col-lg-8">
                            <h3>{t('can_we_help_you')}</h3>
                            <SmartForm 
                                successTitle={t('your_message_has_been_sent_successfully')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="room_inquiry_form" 
                                formName="room-inquiry" 
                                mailSubject="Tiliana megkeresés"
                                stateProps={["name", "email", "phone", "message"]}>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_name')}</label>
                                            <input name="name" type="text" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_email')}</label>
                                            <input name="email" type="email" required />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_phone')}</label>
                                            <input name="phone" type="phone" required />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="single-input">
                                            <label>{t('offer_request')}</label>
                                            <textarea className="form-control" name="message" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div class="single-input">
                                            <button className="sent-btn" type="submit">
                                                {t('send_message')}
                                            </button>
                                        </div>
                                    </div>                                   
                                </div>
                            </SmartForm>
                        </div>
                        <div class="row w-100">
                            <div className="widget col-lg-8 mt-60">
                                <InstagramWidget data={instaFeed} />
                            </div>
                        </div>
                        <SocialShare title={data.title} hashtags={t('room_keywords')} className="mt-60 col-sm-12" />
                    </div>
                </div>
            </div>
        )
    }
}
    
export default withTranslation()(Room)