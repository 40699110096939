import React from "react";
import './instagram-widget.scss'

export default ({ data }) => (
    <>
        {
        /*    <div class="instagram-widget-area">
            <div className="widget-title col-sm-12">
                <h3>Instagram</h3>
            </div>
            <div className="widget-instagram">
                <ul id="Instafeed">
                {data.map(({node: item}) => (
                    <li className="instagram-icon gradient-hover col-sm-3">
                        <a href={"http://www.instagram.com/p/" + item.id} target="_blank">
                            <img sizes={item.localFile.childImageSharp.fluid.sizes} src={item.localFile.childImageSharp.fluid.sizes} srcSet={item.localFile.childImageSharp.fluid.srcSet} />
                        </a>
                    </li>
                ))}
                </ul>
            </div>
        </div>
        */
        }
    </>
)