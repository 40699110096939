import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Room from "../components/Room"

const RoomPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} bgImageOverlay={true} />
          <Room data={fields} content={data.page.html} localeData={localeData} />
      </Layout>
    );
};
export default withI18next()(RoomPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {       
      html
      frontmatter {
        slug
        title
        description
        price
        bed
        extraBed
        view
        wifi
        breakfast
        max_guest
        roomId
        roomSlides {
          image {
            childImageSharp {
              fluid(maxWidth: 770, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }          
        }
      }
    }
    bgImage: file(relativePath: { eq: "rooms/room_header_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

}`