

export function slickCarouselInit() {

    const $ = require('jquery')
    window.jQuery = $;
    window.$ = $;

    require('slick-carousel')

    $('.room-image-large').each(function(){
        var $this = $(this);
        var $thumb = $this.siblings('.room-image-thumbs');
        $this.slick({
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            dots: false,
            infinite: true,
            centerMode: false,
            centerPadding: 0,
            asNavFor: $thumb,
        });
    });
    $('.room-image-thumbs').each(function(){
        var $this = $(this);
        var $details = $this.siblings('.room-image-large');
        $this.slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            dots: false,
            infinite: true,
            focusOnSelect: true,
            centerMode: true,
            centerPadding: 0,
            arrows: false,
            prevArrow: '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
            asNavFor: $details,
            responsive: [
            {
              breakpoint: 1024,
              settings: {
              }
            },
            {
              breakpoint: 600,
              settings: {
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
              }
            }
          ]
            
        });
    });
}